.reglement-interieur-page {
    padding: 20px;
  }
  
  .introduction-text {
    font-size: 1.2em;
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .reglement-interieur-page h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .reglement-interieur-page .btn-primary {
    font-size: 1.2em;
    padding: 10px 20px;
  }
  