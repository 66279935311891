.top-bar {
    background-color: #f8f9fa;
    padding: 10px 0;
    font-size: 0.9rem;
    color: #333;
  }
  
  .top-bar p {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: left;
  }
  
  .top-bar a {
    margin-left: 10px;
    color: #3b5998;
    text-decoration: none;
  }
  
  .top-bar a:hover {
    text-decoration: underline;
  }
  
  .icon-text {
    margin-left: 5px; /* Ajuste la marge selon tes besoins */
    margin-right: 15px; /* Ajuste la marge selon tes besoins */
  }
  
  .icon-text a {
    margin-left: 5px; /* Ajuste la marge selon tes besoins */
  }