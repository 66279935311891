.cotisations-table {
    margin: 20px auto;
    width: 80%;
  }
  
  .cotisations-table th,
  .cotisations-table td {
    text-align: center;
  }
  
  .cotisations-table th {
    background-color: #f8f9fa;
  }
  
  .cotisations-table td {
    vertical-align: middle;
  }
  
  .pass-visiteur,
  .terrains-interieurs {
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .pass-visiteur h2,
  .terrains-interieurs h2 {
    margin-bottom: 15px;
  }
  
  .pass-visiteur p,
  .terrains-interieurs p {
    margin-bottom: 10px;
  }
  