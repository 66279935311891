.padel-courses-container {
    padding: 20px;
  }
  
  .courses-table th, .courses-table td,
  .schedule-table th, .schedule-table td {
    text-align: center;
    vertical-align: middle;
  }
  
  .courses-table th, .schedule-table th {
    background-color: #f8f9fa;
  }
  
  .info-card {
    background-color: #28a745;
    color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .info-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
  
  .price-button {
    font-size: 1.2em;
    font-weight: bold;
  }
  
  .banner-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
    width: 100%;
    max-height: 200px; /* Adjust the max-height to your preference */
    object-fit: contain; /* Ensures the image keeps its aspect ratio */
  }
  
  
  .alert-info, .alert-danger {
    font-size: 1em;
  }
  
  .alert-info {
    background-color: #d1ecf1;
    color: #0c5460;
  }
  
  .alert-danger {
    background-color: #f8d7da;
    color: #721c24;
  }
  
  .text-center {
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .padel-courses-container {
      padding: 10px;
    }
    
    .info-card, .courses-table, .schedule-table {
      margin-bottom: 20px;
    }
    
    .courses-table th, .courses-table td,
    .schedule-table th, .schedule-table td {
      font-size: 0.9rem;
    }
  }
  