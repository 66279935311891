.cours-hiver-container {
    padding: 20px 0;
    text-align: center;
  }
  
  .button-group {
    margin-bottom: 20px;
  }
  
  .cours-content {
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .cours-content h3 {
    color: #007bff;
  }
  
  .cours-details {
    text-align: left;
  }
  
  .cours-details p {
    font-size: 1.1rem;
  }
  
  .prix {
    font-weight: bold;
    color: #e60000;
  }
  
  .alert-info {
    background-color: #e7f3fe;
    border-color: #d6e9f9;
  }
  
  .mt-3 {
    margin-top: 1rem;
  }
  
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  
  .text-center {
    text-align: center;
  }
  