.contact-page-container {
    margin-top: 50px;
  }
  
  .contact-form-card, .location-card {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    height: 100%;
  }
  
  .contact-form-card h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #343a40;
  }
  
  .location-card p {
    margin-bottom: 10px;
    color: #343a40;
  }
  
  .location-card i {
    margin-right: 10px;
  }
  
  .map-container {
    width: 100%;
    height: 300px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .contact-page-container {
      margin-top: 30px;
    }
  
    .contact-form-card, .location-card {
      margin-bottom: 30px;
    }
  }
  