.stages-page {
  margin-top: 20px;
}

.banner-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.stages-table th, .stages-table td {
  text-align: center;
  vertical-align: middle;
}

.stages-table th {
  background-color: #f8f9fa;
}

.price-button {
  font-size: 1.2em;
  font-weight: bold;
}

.formula-card {
  color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
}

.formula-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.formula-icon {
  font-size: 3em;
  text-align: center;
  margin-bottom: 10px;
}

.formula-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}

.formula-description {
  list-style-type: disc;
  padding-left: 20px;
  font-size: 1em;
}

.information-section {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.alert-danger {
  font-size: 1em;
}

button.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

button.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}
