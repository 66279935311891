.partners-carousel {
    padding: 20px 0;
  }
  
  .partners-carousel img {
    max-height: 100px; 
    object-fit: contain;
  }
  
  .partners-carousel h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  