.footer-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-bottom img.footer-logo {
  height: 30px; /* Ajustez la hauteur selon vos besoins */
  margin-left: 10px; /* Espace entre le texte et le logo */
}

.bg-primary {
  background-color: #007bff; /* Couleur de fond principale */
}

.bg-dark {
  background-color: #343a40; /* Couleur de fond secondaire */
}

.text-white {
  color: #fff; /* Couleur du texte */
}

.pt-4 {
  padding-top: 1.5rem; /* Espacement en haut */
}

.py-3 {
  padding-top: 1rem; /* Espacement vertical */
  padding-bottom: 1rem;
}

.text-center {
  text-align: center; /* Alignement du texte */
}

a.text-white {
  color: #fff;
  text-decoration: none;
}

a.text-white:hover {
  color: #f8f9fa;
}

.contact-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem; /* Espacement entre les lignes */
}

.contact-info i {
  margin-right: 0.5rem; /* Espacement entre l'icône et le texte */
}
