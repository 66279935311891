.committee-member .card-img-top {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin: 20px auto 0 auto; /* Ajouter une marge supérieure */

  }

  .committee-member .card-body {
    text-align: center; /* Centrer le contenu textuel */
  }