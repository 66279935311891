.carousel-container {
  position: relative;
  margin: 0;
}

.container-fluid {
  padding: 0;
}

.carousel-title {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -20%);
  z-index: 10;
  text-align: center;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.carousel-title h1 {
  font-size: 64px;
  font-family: 'Montserrat', sans-serif;
}

.fixed-buttons {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  display: flex;
  flex-direction: row; /* Affiche les boutons en ligne */
}

.carousel-button {
  margin: 10px;
  padding: 16px 32px;
  font-size: 20px;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.carousel-button:hover {
  background-color: #0056b3;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.carousel-caption {
  display: none;
}

.carousel-indicators {
  display: none;
}

@media (max-width: 768px) {
  .carousel-title {
    top: 10%;
    left: 50%;
    transform: translate(-50%, -10%);
  }

  .carousel-title h1 {
    font-size: 48px;
  }

  .fixed-buttons {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    flex-direction: column; /* Affiche les boutons en colonne */
  }

  .carousel-button {
    font-size: 18px;
    padding: 14px 28px;
    margin: 5px 0; /* Ajuste les marges pour les boutons en colonne */
  }
}
