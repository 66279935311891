.tarifs-page {
  padding: 20px;
}

.text-center button {
  margin: 0 10px;
}

.combined-prices {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.combined-prices h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.priority-text {
  color: #ff0000;
  font-weight: bold;
  font-size: 1.2rem;
}

.inscription-details h4 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.inscription-details ul {
  list-style-type: none;
  padding: 0;
}

.inscription-details ul li {
  margin-bottom: 10px;
}

.inscription-details ul li a {
  color: #007bff;
  text-decoration: none;
}

.inscription-details ul li a:hover {
  text-decoration: underline;
}

.inscription-details ul ul {
  margin-top: 10px;
}

.inscription-details ul ul li {
  margin-bottom: 5px;
  margin-left: 20px;
}

.availability-banner {
  margin-bottom: 20px;
}
