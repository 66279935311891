.scroll-to-top {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 1000;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.scroll-to-top.show {
  opacity: 1;
  visibility: visible;
}

.scroll-to-top div {
  background-color: #10a102;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s;
}

.scroll-to-top div:hover {
  background-color: #0b8300;
}

.scroll-to-top svg {
  color: white;
  font-size: 24px;
}
