.next-event {
  background-color: #f9f9f9; /* Couleur de fond plus douce */
  padding: 40px 30px; /* Espacement légèrement augmenté */
  border-radius: 0; /* Coins non arrondis */
  box-shadow: none; /* Retirer les ombres */
  width: 100%; /* Prendre toute la largeur */
  margin: 0; /* Pas de marge */
}

.next-event .image-container {
  text-align: center;
  margin-bottom: 30px; /* Plus d'espace pour les écrans plus petits */
}

.next-event img {
  max-width: 80%; /* Utilise toute la largeur disponible */
  height: auto;
  border-radius: 5%; /* Rond */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre légère */
}

.next-event .text-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centrer verticalement */
  height: 100%;
}

.next-event h2 {
  font-size: 2.5rem; /* Taille de police augmentée */
  margin-bottom: 20px;
}

.next-event-title {
  text-align: left;
}

@media (max-width: 768px) {
  .next-event-title {
    text-align: center; /* Centrer le texte pour les petits écrans */
  }
}

.next-event p {
  font-size: 1.1rem; /* Taille de police légèrement augmentée */
  margin-bottom: 20px;
  line-height: 1.6;
}

.next-event .btn-primary {
  font-size: 1.1rem; /* Taille de police légèrement augmentée */
  padding: 12px 24px; /* Plus d'espace pour un bouton plus grand */
}

.container-fluid {
  padding: 0; /* Pas de padding pour que le conteneur prenne toute la largeur */
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
