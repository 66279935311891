.work-in-progress-page {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .work-in-progress-image {
    max-width: 50%; /* Ajuster cette valeur pour la taille souhaitée */
    height: auto;
    margin-bottom: 20px;
  }
  
  .work-in-progress-page h2 {
    color: #343a40;
    font-size: 24px;
  }
  