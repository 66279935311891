.nos-ecoles {
  padding: 40px 0;
}

.nos-ecoles h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.nos-ecoles .card {
  border: none;
  transition: transform 0.2s, box-shadow 0.2s;
}

.nos-ecoles .card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.nos-ecoles .image-container {
  width: 100%;
  padding-top: 100%; /* Maintains a 1:1 aspect ratio */
  position: relative;
}

.nos-ecoles .image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nos-ecoles .card-title {
  font-size: 1.2rem;
  margin-top: 10px;
  font-family: 'Montserrat', sans-serif;
  color: #333;
}

/* Remove underline from links */
.nos-ecoles a {
  text-decoration: none;
  color: inherit; /* Keep the same color as the text */
}

.nos-ecoles a:hover {
  color: #0056b3; /* Optional: change color on hover */
}

.nos-ecoles .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.nos-ecoles .btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}
