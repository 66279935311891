.reservations-table {
    margin: 20px auto;
    width: 80%;
  }
  
  .reservations-table th,
  .reservations-table td {
    text-align: center;
  }
  
  .reservations-table th {
    background-color: #f8f9fa;
  }
  
  .reservations-table td {
    vertical-align: middle;
  }
  
  .reservation-options {
    margin: 20px auto;
  }
  
  .phone-reservation {
    font-size: 1.2rem;
  }
  
  .alert {
    font-size: 1.1rem;
  }
  