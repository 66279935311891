.events-container {
  margin-top: 20px;
}

.events-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.table {
  margin-top: 20px;
}

.table td, .table th {
  vertical-align: middle;
  text-align: center;
}

.table .btn-danger {
  margin: 0 auto;
}

.event-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.slider-option {
  margin: 0 10px;
  padding: 5px 10px;
  cursor: pointer;
  position: relative;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.slider-option.active {
  color: #0056b3;
}

.slider-option.active::after {
  content: '';
  display: block;
  margin: 0 auto;
  height: 3px;
  width: 100%;
  background: #0056b3;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.slider-separator {
  height: 40px; /* Height to connect with the bottom line */
  width: 2px;
  background: black;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
}

.events-table {
  transition: opacity 0.3s ease;
}

.highlight {
  background-color: #ffeb3b !important;
}
