.banner {
    position: relative;
    width: 100%;
    height: 200px; /* Ajustez la hauteur selon vos besoins */
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .banner-text {
    background: rgba(0, 0, 0, 0.1); /* Fond semi-transparent pour le texte */
    color: white;
    padding: 20px;
    border-radius: 20px;
  }
  
  .banner h1 {
    margin: 0;
    font-size: 2.5rem;
  }
  