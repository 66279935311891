.presentation-page {
    margin-top: 20px;
  }
  
  .highlight-section {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .gallery-section {
    margin-top: 20px;
  }
  
  .committee-section {
    margin-top: 20px;
  }
  
  .committee-member {
    margin-bottom: 20px;
  }
  