.padel-stages-container {
    padding: 20px;
  }
  
  .banner-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .info-card {
    background-color: #28a745;
    color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .info-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
  
  .text-center {
    text-align: center;
  }
  
  button {
    margin: 5px;
  }
  
  @media (max-width: 768px) {
    .padel-stages-container {
      padding: 10px;
    }
  
    .info-card, .banner-image {
      margin-bottom: 20px;
    }
  }
  