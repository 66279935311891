.courts-info-section {
    text-align: center;
    padding: 50px 0;
    background-color: #f0f0f0; /* Légèrement grisé */  }
  
  .courts-info-section h2 {
    font-size: 36px;
    color: #333;
    margin-bottom: 40px;
  }
  
  .courts-info {
    display: flex;
    justify-content: center;
    gap: 40px;
  }
  
  .court {
    text-align: center;
    position: relative;
    padding: 0 15px;
  }
  
  .court:not(:last-child)::after {
    content: "";
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    height: 50%;
    width: 1px;
    background-color: #ccc;
  }
  
  .court .number {
    display: block;
    font-size: 48px;
    font-weight: bold;
    color: #333;
  }
  
  .court .label {
    display: block;
    font-size: 18px;
    color: #007bff;
    margin-top: 10px;
    
  }
  