.hero-about {
  background-color: #f9f9f9; /* Couleur de fond plus douce */
  padding: 40px 30px; /* Espacement légèrement augmenté */
  border-radius: 0; /* Coins non arrondis */
  box-shadow: none; /* Retirer les ombres */
  width: 100%; /* Prendre toute la largeur */
  margin: 0; /* Pas de marge */
}

.hero-about .image-container {
  text-align: center;
  margin-bottom: 30px; /* Plus d'espace pour les écrans plus petits */
}

.hero-about img {
  max-width: 80%; /* Utilise toute la largeur disponible */
  height: auto;
  border-radius: 5%; /* Rond */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 1); /* Ombre légère */
}

.hero-about h2 {
  font-size: 2.5rem; /* Taille de police augmentée */
  margin-bottom: 20px;
}

/* Media queries pour ajuster l'alignement en fonction de la taille de l'écran */
@media (max-width: 768px) {
  .hero-title {
    margin-left: 0; /* Réinitialiser la marge pour les petits écrans */
    text-align: center; /* Centrer le texte pour les petits écrans */
  }
}

.hero-about p {
  font-size: 1.1rem; /* Taille de police légèrement augmentée */
  margin-bottom: 20px;
  line-height: 1.6;
}

.hero-about .btn-primary {
  font-size: 1.1rem; /* Taille de police légèrement augmentée */
  padding: 12px 24px; /* Plus d'espace pour un bouton plus grand */
}

.container-fluid {
  padding: 0; /* Pas de padding pour que le conteneur prenne toute la largeur */
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
