.history-page {
  margin-top: 20px;
}

.history-page h1, .history-page h2, .history-page p {
  color: #343a40;
  text-align: center; /* Centrer les titres et le texte */
}

.history-page .presidents-table {
  margin-top: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.history-page .presidents-table th, .history-page .presidents-table td {
  text-align: center;
  vertical-align: middle;
}

.carousel-control-next-icon-custom,
.carousel-control-prev-icon-custom {
  width: 50px;
  height: 50px;
  background-size: 100%, 100%;
  background-image: none;
}

.carousel-control-next-icon-custom::before,
.carousel-control-prev-icon-custom::before {
  font-size: 50px;
  color: #343a40;
}

.carousel-control-next {
  right: -60px;
}

.carousel-control-prev {
  left: -60px;
}

@media (max-width: 768px) {
  .history-page h1 {
    font-size: 28px;
  }

  .history-page h2 {
    font-size: 24px;
  }

  .history-page p {
    font-size: 14px;
  }

  .carousel-control-next {
    right: -30px;
  }

  .carousel-control-prev {
    left: -30px;
  }
}
