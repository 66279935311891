.subscriptions-table {
    margin: 20px auto;
    width: 80%;
  }
  
  .subscriptions-table th,
  .subscriptions-table td {
    text-align: center;
  }
  
  .subscriptions-table th {
    background-color: #f8f9fa;
  }
  
  .subscriptions-table td {
    vertical-align: middle;
  }
  
  .availability-banner {
    margin: 20px auto;
  }
  
  .reservation-details {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .reservation-details h4 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .reservation-details ul {
    list-style-type: none;
    padding: 0;
  }
  
  .reservation-details ul li {
    margin-bottom: 10px;
  }
  
  .reservation-details ul li a {
    color: #007bff;
    text-decoration: none;
  }
  
  .reservation-details ul li a:hover {
    text-decoration: underline;
  }
  