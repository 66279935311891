.location-card {
  width: 100%;
  max-width: 600px; /* Augmentez cette valeur si vous voulez des cartes plus larges */
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  text-align: center; /* Centre le contenu du texte */
}

.map-container iframe {
  border-radius: 8px;
  border: 2px solid #007bff;
  width: 100%;
  height: 300px;
}
