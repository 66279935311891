.navbar-custom {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.nav-item {
  font-size: 1rem;
  margin-left: 10px; /* Réduit l'espace entre les éléments du menu */
  color: #333;
  position: relative;
  transition: color 0.3s ease;
}

.nav-icon {
  margin-right: 5px;
}

.navbar-nav .dropdown-menu {
  border-radius: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  border-radius: 0;
}

.navbar-brand-custom {
  margin-right: 10px; /* Réduit l'espace entre le logo et les éléments du menu */
  padding: 0; /* Enlève tout padding pour rapprocher le logo */
}

.navbar-brand img {
  max-height: 60px;
  margin-right: 10px; /* Réduit l'espace entre le logo et les éléments du menu */
}

.navbar-nav .nav-link {
  color: #333;
  transition: color 0.3s ease;
}

.navbar-nav .nav-link:hover {
  color: #0056b3;
}

.navbar-nav .nav-item .dropdown-menu .dropdown-item:hover {
  background-color: #f8f9fa;
}

.submenu-arrow {
  margin-left: auto;
}

/* Hide default dropdown arrows */
.dropdown-toggle::after {
  display: none !important;
}

/* Custom arrow for main dropdown menus */
.main-menu-arrow {
  margin-left: 5px;
}

/* Hover animations */
.nav-item::before,
.nav-item::after {
  content: '';
  position: absolute;
  height: 3px;
  width: 0;
  bottom: -5px;
  left: 50%;
  background-color: #0056b3;
  transition: width 0.3s ease, left 0.3s ease;
}

.nav-item::after {
  top: -5px;
  bottom: auto;
}

.nav-item:hover::before,
.nav-item:hover::after {
  width: 100%;
  left: 0;
}

.dropdown-menu {
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}

.dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
