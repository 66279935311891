.accidents-page {
    padding: 20px;
  }
  
  .banner {
    position: relative;
    text-align: center;
    color: white;
  }
  
  .banner-image {
    width: 100%;
    height: auto;
  }
  
  .banner-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  .procedure-list {
    list-style: none;
    padding-left: 0;
  }
  
  .procedure-list li {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .icon {
    margin-right: 10px;
    font-size: 1.5rem;
    color: #007bff;
  }
  
  .procedure-list a {
    color: #007bff;
    text-decoration: underline;
  }
  
  .procedure-list a:hover {
    text-decoration: none;
  }
  
  .important-note {
    background-color: #f8d7da;
    color: #721c24;
    padding: 15px;
    border: 1px solid #f5c6cb;
    border-radius: 5px;
    text-align: center;
  }
  