.cafeteria-container {
    padding: 50px 0;
  }
  
  .menu-section, .photo-section, .hours-section, .owners-section {
    margin-bottom: 40px;
  }
  
  .menu-item {
    margin-bottom: 20px;
  }
  
  .photo-section img {
    width: 100%;
    height: auto;
  }
  
  .hours-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .hours-section li {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .owner-card {
    margin-bottom: 20px;
  }
  